import React, { useState } from "react";
import "./Login.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { store } from "../stores/store";
import { useStoreActions } from "easy-peasy";

const useStyles = makeStyles((theme) => ({
  btn: {
    position: "relative",
    top: "10px",
    color: theme.palette.primary.light,
  },
  root: {
    backgroundColor: "rgb(61, 61, 61)",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

const Login = () => {
  const setUserStore = useStoreActions((actions) => actions.set_user);
  const history = useHistory();
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });

  const handleLogin = async (e) => {
    e.preventDefault();
    const request = await fetch("/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((j) => j.json());
    if (request.success) {
      setUserStore(true);
      setTimeout(() => {
        history.push("/admin");
      }, 500);
    } else {
      alert(request.message);
    }
  };

  return (
    <div className="mainContainer">
      <form className={classes.root} noValidate onSubmit={handleLogin}>
        <TextField
          style={{ marginBottom: "30px", color: "white" }}
          InputLabelProps={{ style: { color: "white" } }}
          inputProps={{ style: { color: "white" } }}
          label="Email"
          type="email"
          variant="outlined"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          color="primary"
        />
        <TextField
          type="password"
          color="primary"
          InputLabelProps={{ style: { color: "white" } }}
          inputProps={{ style: { color: "white" } }}
          label="Password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          type="submit"
        >
          Log In
        </Button>
      </form>
    </div>
  );
};

Login.getInitialProps = async (props) => {
  if (props.isServer) {
    if (props.req.user) {
      return { redirectTo: "/admin" };
    }
  } else {
    const user = store.getState().user;
    if (user) {
      return { redirectTo: "/admin" };
    }
  }
};

export default Login;
